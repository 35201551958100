<template>
    <main>
        <div id="primary" class="height-full">
            <div class="container">
                <div class="row pt-3">
                    <div class="col-lg-8">
                        <figure class="avatar avatar-md">
                            <img src="../assets/img/logo-255x255.png" alt="">
                        </figure>
                        <span class="font-weight-bold s-18 pt-3 ml-2">两粒米福利云 | 米粒付</span>

                        <!-- <span class="s-14 pt-3 ml-5">硬件采购</span>
                        <span class="s-14 pt-3 ml-5">解决方案</span> -->
                        <!-- <span class="s-14 pt-3 ml-5">诚征代理</span> -->
                    </div>
                    <div class="col-lg-4">
                        <div class=" float-right">
                           <!-- <button type="button" class="btn btn-sm btn-outline-light r-15">客服热线</button>
                            <span class="s-14 pt-3 ml-2">4008 888 888</span> -->
							<!-- <button type="button" class="btn btn-sm btn-outline-light r-15">诚征代理</button>
							<span class="s-14 pt-3 ml-2">4000 526 528</span> -->
                        </div>
                    </div>
                </div>
                <div class="row p-t-b-100 ">
                    <div class="col-lg-8 m-t-150">
                        <div class="text-white">
                            <!-- <h1 class="font-weight-bold s-48">两粒米，让点餐更简单！</h1> -->
                            <!-- <h1 class="font-weight-bold s-48">和两粒米共创，</h1>
                            <h1 class="font-weight-bold s-48">打造共赢生态，共享智慧餐饮红利</h1> -->
                        </div>
                    </div>
                    <div class="col-lg-4 paper-card m-t-50 r-20">
                        <div class="">
                            <!-- <figure class="avatar avatar-xl">
                            <img src="./static/logo-255x255.png" alt="">
                            </figure> -->
                            <p class="text-muted mb-0 pb-0">福利云</p>
                            <h3 class="mt-0 pt-0 text-black font-weight-bold s-24">帐号登录</h3>
                            <p class="p-t-b-10 text-red">
                                {{err}}
                            </p>
                        </div>
                        <div class="form-material">
                            <!-- <div class="form-group has-icon"><i class="icon-user-o"></i>
                                <input type="number" v-model="userid" class="form-control form-control-lg"
                                       placeholder="手机号">
                            </div>
                            <div class="form-group has-icon"><i class="icon-key3"></i>
                                <input type="password" v-model="password" class="form-control form-control-lg"
                                       placeholder="密码">
                            </div> -->
                            <div class="row clearfix">
                                <div class="col-sm-12">
									<!-- 密码登录 -->
									<template v-if="login_mode == 1">
                                    <div class="form-group">
                                        <div class="form-line">
                                            <input v-model="userid" type="text" class="form-control"
                                                placeholder="请输入手机号/邮箱" autocomplete="new-userid">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-line">
                                            <input v-model="password" type="password" class="form-control"
                                                placeholder="请输入密码" autocomplete="new-password">
                                        </div>
                                    </div>
									</template>
									
									<!-- 验证码登录 -->
									<template v-if="login_mode == 0">
									<div class="form-group">
									    <div class="form-line">
									        <input v-model="phone_number" type="number" class="form-control"
									            placeholder="请输入手机号" autocomplete="new-phone_number">
									    </div>
									</div>
									<div class="form-group">
									    <div class="form-line" 
										style="display: flex; align-items: center;justify-content: space-between;">
									        <input v-model="valid_code" type="number" class="form-control"
									            placeholder="请输入验证码" >
											<button type="button" class="btn btn-primary btn-sm r-20"
											@click="sendMessage"
											:disabled="disabled" 
											v-show="show"
											>发送验证码</button>	
											
											<button v-show="!show" class="btn btn-secondary btn-sm r-20">{{count}}秒后重发</button>
									    </div>
									</div>
									</template>
									
                                </div>
                            </div>
                            <button class="btn btn-primary btn-lg r-20 btn-block font-weight-bold blue lighten-2" @click="login">
                                登录
                            </button>

                            <!-- <button class="btn btn-primary btn-lg r-20 btn-block font-weight-bold indigo"
                                @click="login">
                                登录
                            </button> -->

                            <div class="d-flex justify-content-between align-items-center mt-3">
								<div><button @click="switchLogingMode" type="button" class="btn btn-link s-14">
								{{login_mode==0?'密码登录':'验证码登录'}}
								</button></div>
                                <!-- <div><span class="text-black s-16">忘记密码</span></div> -->
                                <!-- <div><span class="text-black s-14">没有帐号，立即注册</span></div> -->
                            </div>

                            <!-- <p class="forget-pass">忘记密码 ?</p> 
                            <a href="#" class="btn btn-lg btn-block">
                                <i class="icon-wechat"></i> 微信登录
                            </a> -->
                            <!-- <a href="#" class="btn btn-lg btn-block btn-social twitter">
                                <i class="icon-twitter"></i> Login with Twitter
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- #primary -->
    </main>
</template>

<script>
    export default {
        data: function() {
            return {
                userid: '',
                password: '',
                err: '',
				
				login_mode: 1,
				phone_number: '',
				valid_code: '',
				disabled: false,
				show: true,
				count: '',
				timer: null,
            }
        },
        created: function() {
            // let self = this;
        },
        mounted: function() {
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (token!=null) {
				
				this.axios.get(this.GLOBAL.baseURI + 'checkUserLogin', {
				        params: {
				            token: token,
				        }
				    })
				    .then(function(response) {
				        console.log(response);
				        return response.data;
				    })
				    .catch(function(error) {
				        console.log(error);
				    })
				    .then(function(data) {
				        // always executed
				        console.log(data);
				        if (data.code == 200) {
							self.$router.replace({name: 'dashboard'});
				        }
				    });
					//END
				
			}
		},
		
        methods: {
            login() {
                let self = this;
                console.log(self.userid, self.password);
                // return;
				
				let postData = {};
				
				if(self.login_mode==0){
					if (!self.phone_number) {
						self.err = '帐号不能为空';
						return;
					}
					
					if (!self.valid_code) {
						self.err = '验证码不能为空';
						return;
					}
					
					postData = {
					    phone_number: self.phone_number,
					    valid_code: self.valid_code,
					}
				}
				else{
					if (!self.userid) {
						self.err = '帐号不能为空';
						return;
					}

					if (!self.password) {
						self.err = '密码不能为空';
						return;
					}
					
					postData = {
					    userid: self.userid,
					    password: self.password,
					}
				}

                self.err = '';
				
				let url = self.login_mode==0?'loginBySms':'login';

                this.axios.get(this.GLOBAL.baseURI + url, {
                        params: postData
                    })
                    .then(function(response) {
                        console.log(response);
                        return response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    })
                    .then(function(data) {
                        // always executed
                        console.log(data.data);
                        if (data.code == 200) {
                           
						   if(data.data.result_code == 'success'){
							   console.log('--- token', localStorage, data.data.access_token);
							   
							   // localStorage.setItem("SETTING", JSON.stringify(data.data.setting));
							   
							   let loginData = data.data;
							   // delete loginData['setting']
							   localStorage.setItem("LOGINDATA", JSON.stringify(loginData));
							   
							   localStorage.setItem("TOKEN", data.data.access_token);
							   
							   // self.getDeptList();
							   self.$store.commit('setLoginStatus', true);
							   self.$router.replace({name: 'dashboard'});
						   }
						   else{
							   self.err = data.data.result_msg;
						   }
							
                        } else if (data.code == '3001') {
                            // alert(data.message)
                            self.err = '帐号不存在';
                            // alert('帐号不存在')
                        } else if (data.code == '3002') {
                            // alert(data.message)
                            self.err = '密码错误';
                            // alert('密码错误')
                        } else if (data.code == '3008') {
                            // alert(data.message)
                            self.err = '没有权限';
                            // alert('没有权限')
                        } else if (data.code == '9999') {
                            // alert(data.message)
                            self.err = '商户类型错误';
                            // alert('商户类型错误')
                        } else if (data.code == '1507') {
                            // alert(data.message)
                            self.err = '授权到期';
                            // alert('授权到期')
                        } else {
                            // alert(data.message)
                            self.err = data.message;
                            // alert('帐号异常')
                        }

                    });
            },
			
			//TODO：B企业可以设置一个总部，可以看分部；或者A平台取得不同的企业
            getDeptList() {
                var self = this;

                this.axios.get(this.GLOBAL.baseURI + 'getDeptList', {
                        params: {
                            token: localStorage.getItem("TOKEN"),
                        }
                    })
                    .then(function(response) {
                        console.log(response);
                        return response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    })
                    .then(function(data) {
                        // always executed
                        console.log(data);
                        if (data.code == 200) {
                            localStorage.setItem("DEPTLIST", JSON.stringify(data.data.dept_list));
							self.$store.commit('setLoginStatus', true);
							// console.log('--- isLogin @login.vue ->',self.$store.state.isLogin)
							
                            // location.href = 'app.html';
							// self.$router.push({path: '/admin/account'});
							self.$router.replace({name: 'dashboard'});
                        }
                    });

            },
			
			////
			switchLogingMode(){
				let self = this;
				self.login_mode = self.login_mode == 0?1:0;
				
				if(self.login_mode == 0){
					self.valid_code = '';
				}
				else{
					self.password = '';
				}
			},
			
			sendMessage(){
				let self = this;
				
				if(!self.phone_number){
					self.$toasted.error('请输入手机号', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if(self.phone_number.length != 11){
					self.$toasted.error('手机号格式错误', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if(self.disabled) return;
			
				self.disabled = true;
			
				// 组装数据
				let postData = {
					phone_number: self.phone_number,
				}
				
				console.log('--- postData ->',postData)
			
				this.axios.get(this.GLOBAL.baseURI + 'sendLoginMessage', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
			
						self.disabled = false;
			
						if (data.code == 200) {
							
							if(data.data.result_code == 'success'){
								self.$toasted.success('发送成功', {
									position: 'top-center',
								}).goAway(2000)
								
								//倒计时
								self.countdown();
							}
							else{
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
								}).goAway(2000)
							}
						} 
						else if (data.code == 403) {
							 self.$router.push({ name: '403'});
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} 
						else {
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
			},
			
			countdown() {
				const TIME_COUNT = 60;
				if (!this.timer) {
					this.count = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
			},

        },
    }
</script>

<style>
    #primary {
        color: rgb(255, 255, 255);
        background: url(../assets/img/bg-home.jpg) center center / cover;
        /* padding: 150px 0px; */
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition-delay: 9999s;
        -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    }
</style>
